import React from 'react';
import './Gallery.css'; 

const Gallery = ({ images }) => {
  // Static images to be displayed in the gallery
  const staticImages = [
    '/img/partner/1.png',
    '/img/partner/2.png',
    '/img/partner/3.png',
    '/img/partner/4.png',
    '/img/partner/5.png',
    '/img/partner/6.png',
    '/img/partner/7.png',
    '/img/partner/8.png',
    '/img/partner/9.png',
    '/img/partner/10.png',
    '/img/partner/11.png',
    '/img/partner/12.png',
  ];

  return (
    <div className="gallery">
      {/* Static images */}
      {staticImages.map((image, index) => (
        <div className="gallery-item" key={`static-${index}`}>
          <img src={image} alt={`Partner ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default Gallery;
