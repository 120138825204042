import React from 'react'
import './RequestADemo.css';
import DemoForm from '../components/DemoForm';
const RequestADemo = () => {
  return (
    <>
      <div className="requestdemobanner">
        <div className="container">
            <div className="reqrow01">
                    <h1>Request A Demo</h1>
                    <h2>This can be the Request a Demo page as well
                    </h2>
                    <p>Connect with us today!</p>
            </div>
        </div>
      </div>
      <div className="formrow02">
        <div className="container">
            <DemoForm />
        </div>
      </div>
    </>
  )
}

export default RequestADemo
