import React from "react";
import "./Home.css";
import Gallery from "../components/Gallery";
//import { useNavigate } from "react-router-dom";
const Home = () => {
  //  const navigate = useNavigate(); // Initialize useNavigate hook
  const trialbtn = () => {
    window.location.href = "#";
  };
  return (
    <>
      <div className="newbanner">
        <div className="container">
          <div className="bannerrows">
            <div className="homerow1">
              <h1>
                ALIGNING OPERATIONS WITH BRAND IDENTITY TO CREATE CONTINUOUS
                IMPROVEMENT
              </h1>
              <h2>
                <div className="blocktext">align</div>{" "}
                <div className="blocktext">improve</div>{" "}
                <div className="blocktext">repeat</div>
              </h2>
              <p>
                With pre-opening and established hotels, QTX ensures direct
                alignment between each operating activity and a hotel’s Brand
                Identity.
              </p>
              <button onClick={trialbtn}>Start a Free Trial Today</button>
            </div>
            <div className="ban-image">
              <img src="/img/banimg.png" alt="Banner" />
            </div>
          </div>
        </div>
      </div>
      <div className="homerow4">
        <div className="container">
          <div className="homerowinner4">
            <h2>COMMERCIAL BENEFITS</h2>
            <div className="fourgridbox">
              <div className="fourgridbox1 fbox">
                <div className="gridimg gim">
                  <img src="/img/icon1.png" alt="Optimize People" />
                </div>
                <div className="gridtext grdc">
                  <h3>Optimize People</h3>
                </div>
              </div>
              <div className="fourgridbox2 fbox">
                <div className="gridimg gim">
                  <img
                    src="/img/icon2.png"
                    alt="Enhance Productivity
"
                  />
                </div>
                <div className="gridtext grdc">
                  <h3>Enhance Productivity</h3>
                </div>
              </div>
              <div className="fourgridbox3 fbox">
                <div className="gridimg gim">
                  <img
                    src="/img/icon3.png"
                    alt="Engage Customers
"
                  />
                </div>
                <div className="gridtext grdc">
                  <h3>Engage Customers</h3>
                </div>
              </div>
              <div className="fourgridbox4 fbox">
                <div className="gridimg gim">
                  <img
                    src="/img/icon4.png"
                    alt="Be Commercial Ready
"
                  />
                </div>
                <div className="gridtext grdc">
                  <h3>Be Commercial Ready</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homerow03">
        <div className="container">
          <div className="homein3">
            <div className="lefttext">
              <h2>
                Aligning Hotel Operations With Brand Identity To Create A
                Culture Of Continuous Improvement
              </h2>
            </div>
            <div className="righimg">
              <img src="/img/align.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="homerow5">
        <div className="container">
          <div className="homerowinner5">
            <h2>SUCCESS STORIES</h2>

            <div className="tworowbox">
              <div className="imgbox">
                <img src="/img/parisian.png" alt="Parisian" />
              </div>
              <div className="imgcontextbox">
                <h3>A MIGHTY SIMULATION – THE PARISIAN</h3>
                <ul>
                  <li>3000 room integrated resort </li>
                  <li>30+ F&B outlets, Leisure facilities and Gaming. </li>
                  <li>
                    Converted 50,000 real time assessments over a 4 days
                    simulation{" "}
                  </li>
                  <li>8000 participants</li>
                </ul>
              </div>
            </div>
            <div className="tworowbox mobres">
              <div className="imgcontextbox">
                <h3>OPTIMIZING OPERATIONS – GRAND HYATT SINGAPORE</h3>
                <ul>
                  <li>Optimized Housekeeping and Grand Club operations</li>
                  <li>Reduced administration time by 20% </li>
                  <li>Improved Front Desk productivity </li>
                  <li>
                    Automated supervisory inspections throughout the Rooms
                    division.
                  </li>
                </ul>
              </div>
              <div className="imgbox">
                <img src="/img/grand-hyatt.png" alt="Grand Hyatt" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homerow6">
        <div className="container">
          <div className="howhead">
            <h2>PRODUCT HIGHLIGHTS</h2>
          </div>
          <div className="homerowinner6">
            <div className="threeimg">
              <div className="timgbox1">
                <img src="/img/1.png" alt="1" />
              </div>
              <div className="timgbox1">
                <img src="/img/2.png" alt="2" />
              </div>
              <div className="timgbox1">
                <img src="/img/3.png" alt="3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homerow7">
        <div className="container">
          <div className="homerowinner7">
            <h2>OUR TRUSTED PARTNERS</h2>
            <div className="gallerybox001">
              <Gallery />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
