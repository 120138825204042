import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./Pricing.css";

const Pricing = () => {
    const [isGROWToggled, setIsGROWToggled] = useState(false);
    const [isGROUPToggled, setIsGROUPToggled] = useState(false);
    const toggleGROW = () => {
      setIsGROWToggled(!isGROWToggled);
    };
  
    const toggleGROUP = () => {
      setIsGROUPToggled(!isGROUPToggled);
    };
    const navigate = useNavigate();
      /* 
  const upgradebtn1 = () => {
     navigate("/request-a-demo"); 
  }; */
  const upgradebtn2 = () => {
    navigate("/request-a-demo"); 
  };
  const upgradebtn3 = () => {
    navigate("/request-a-demo"); // Navigate to the desired route
  };

  return (
    <>
      <div className="pricing page-id-03">
      <div className="requestdemobanner">
        <div className="container">
            <div className="reqrow01">
                    <h1>Choose Your Pricing</h1>
                    <h2>Suspendisse viverra elit at neque feugiat commodo. Sed iaculis</h2>
                    <p>Choose Your Plans</p>
            </div>
        </div>
      </div>
      <div id="pricing" className="pricerow4">
          <div className="bgrow4">
            <div className="container row4text">
              <div className="priceboxes01">
  <div className="pricb1 priceb2">
    <h3>GROW</h3>
    <p className="pr">US$960 per year</p>
    <button className="btn3" onClick={upgradebtn2}>
      START TRIAL
    </button>
    <ul className="list01">
      <li><img src="/img/limg.png" alt="icon" /> 10 user accounts</li>
      <li><img src="/img/limg.png" alt="icon" /> Contactless booking</li>
    </ul>
    {isGROWToggled ? null : (
      <div className="buttonaling">
        <button
          className="tog"
          onClick={toggleGROW}
          style={{
            cursor: "pointer",
            padding: "10px",
            fontSize: "16px",
            display: "none",
          }}
        >
          See All Features
        </button>
      </div>
    )}
    {isGROWToggled && (
      <div style={{ marginTop: "10px" }}>
        <ul className="list01">
          <li><img src="/img/limg.png" alt="icon" /> Retail inventory management</li>
          <li><img src="/img/limg.png" alt="icon" /> Waitlist management</li>
          <li><img src="/img/limg.png" alt="icon" /> Guest profiling</li>
          <li><img src="/img/limg.png" alt="icon" /> Business pulse check</li>
          <li><img src="/img/limg.png" alt="icon" /> Live reporting suite</li>
          <li><img src="/img/limg.png" alt="icon" /> 30+ live KPIs</li>
          <li><img src="/img/limg.png" alt="icon" /> Online payments</li>
          <li><img src="/img/limg.png" alt="icon" /> Integrations</li>
          <li><img src="/img/limg.png" alt="icon" /> Online consultation (Q1, 2025)</li>
        </ul>
      </div>
    )}
  </div>

  <div className="pricb1">
    <h3>GROUP</h3>
    <p className="pr pde0" style={{ opacity: 0 }}>
      Includes 2 users
    </p>
    <button className="btn3" onClick={upgradebtn3}>
    START TRIAL
    </button>
    <ul className="list01">
      <li><img src="/img/limg.png" alt="icon" /> Unlimited users</li>
      <li><img src="/img/limg.png" alt="icon" /> Group users</li>
    </ul>
    {isGROUPToggled ? null : (
      <div className="buttonaling">
        <button
          className="tog"
          onClick={toggleGROUP}
          style={{
            cursor: "pointer",
            padding: "10px",
            fontSize: "16px",
            display: "none",
          }}
        >
          See All Features
        </button>
      </div>
    )}
    {isGROUPToggled && (
      <div style={{ marginTop: "10px" }}>
        <ul className="list01">
          <li><img src="/img/limg.png" alt="icon" /> Live group KPIs</li>
          <li><img src="/img/limg.png" alt="icon" /> Live analytics dashboard</li>
          <li><img src="/img/limg.png" alt="icon" /> Customer demographics</li>
          <li><img src="/img/limg.png" alt="icon" /> Feedback integrations</li>
          <li><img src="/img/limg.png" alt="icon" /> Consultation analysis (Q1, 2025)</li>
        </ul>
      </div>
    )}
  </div>
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
