import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const currentYear = new Date().getFullYear();
  
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  return (
    <>
      <div className="footer footerct">
        <div className="foocol">
        <div className="container">
          <div className="forow">
            <div className="foologo"><Link to="/"><img src="./logo.png" alt="Logo" className="logo-image" /></Link></div>
            <div className="foomenuu">
            <ul className="foonavbar">
        <li>
          <Link to="/">About</Link>
        </li>
        <li>
          <Link to="/">Pricing</Link>
        </li>
        <li>
          <Link to="/">Contact Us</Link>
        </li>
      </ul>
            </div>
            <div className="foocopy"><p>© {currentYear} All Rights Reserved.</p>
            <div className="socialicon">
                <a href='https://www.facebook.com/' target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href='https://www.youtube.com/' target="_blank" rel="noreferrer">
                  <i className="fa fa-youtube-play"></i>
                </a>
              </div>
            </div>
          </div>
        
        </div>
      </div>
            {/* Scroll to Top Button */}
            {isVisible && (
          <button className="scroll-to-top" onClick={scrollToTop}>
           <i class="fa fa-long-arrow-up"></i>
          </button>
        )}
      </div>
    </>
  );
};

export default Footer;
