import React from "react";
import "./About.css";
const About = () => {
  return (
    <>
      <div className="aboutdemobanner">
        <div className="container">
          <div className="reqrow01">
            <h1>THE CRAFT CI JOURNEY</h1>
          </div>
        </div>
      </div>
      <div className="abourow02">
        <div className="container">
            <div className="aboutrowtext">
                <ul className="customlist01">
                    <li>CRAFT CI has served teams that represent over <span>52,000</span> hotel rooms worldwide. </li>
                    <li>A dedicated team in our <span>tenth year of business</span></li>
                    <li>Combined <span>43 years</span> of Hotel, Spa & Wellness operating experience (grass roots to senior leadership)</li>
                    <li>Combined 43 years of Hotel, Spa & Wellness operating experience (grass roots to senior leadership)</li>
                    <li>Combined <span>38 years</span> of software development & technology experience</li>
                    <li><span>Asia/Pacific</span> based and focused</li>
                </ul>
                <div className="mapimg"><img src="/img/map.png" alt="map" /></div>
                <div className="timeimg"><img src="/img/timeline.png" alt="timeline" /></div>
            </div>
        </div>
      </div>
    </>
  );
};

export default About;
